<template>
  <div class="about">
    <van-nav-bar
      title="快速批阅"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />

    <div class="headerInfo">
      <div class="titleAndClass">
        <div class="title">
          <div class="subject">{{ caseInfo.subjectName }}</div>
          <div class="caseTitle">{{ caseInfo.title }}</div>
          <div class="switchSubject" @click="showCase = true">
            <van-image
              width="20px"
              height="20px"
              :src="require('@/assets/images/switch.png')"
            />
            <div>切换学案</div>
          </div>
        </div>
        <div class="class" @click="showClass = true">
          <van-image
            width="20px"
            height="20px"
            :src="require('@/assets/images/switch.png')"
          />
          <div>{{ className }}</div>
        </div>
      </div>

      <div class="bookAndTime">
        <div class="bookAndTree">
          <div class="book">
            {{ caseInfo.bookName ? caseInfo.bookName : "自定义教材" }}
          </div>
          <div class="tree">
            /
            {{ caseInfo.tree_fullname ? caseInfo.tree_fullname : "自定义目录" }}
          </div>
        </div>
        <div class="time">
          {{ caseInfo.startTime }}至{{ caseInfo.blockingTime }}
        </div>
      </div>

      <div class="cir" v-if="caseInfo.forIndex">
        <van-circle
          v-model:current-rate="currentRate1"
          :rate="caseInfo.forIndex.review.percent"
          :speed="200"
          layer-color="#eee"
          color="#18a4e0"
          size="110px"
          :text="`批阅进度${caseInfo.forIndex.review.min}/${caseInfo.forIndex.review.max}`"
        />
        <van-circle
          v-model:current-rate="currentRate2"
          :rate="caseInfo.forIndex.finished.percent"
          :speed="200"
          layer-color="#eee"
          color="rgb(112,181,3)"
          size="110px"
          :text="`学生提交${caseInfo.forIndex.finished.min}/${caseInfo.forIndex.finished.max}`"
        />
      </div>
    </div>

    <div class="studentList">
      <van-tabs v-model:active="isReviewActive">
        <van-tab title="待批阅">
          <ul class="listBox">
            <li v-for="item in studentList" :key="item.id">
              <div class="nameAndReview">
                <div class="name">
                  <van-image
                    width="40px"
                    height="40px"
                    :src="
                      item.sex == '0'
                        ? require('@/assets/images/boy.png')
                        : require('@/assets/images/girl.png')
                    "
                  />
                  <div class="studentName">{{ item.studentName }}</div>
                  <div style="color: #d1d1d1; font-size: 10px">
                    {{ item.is_evaluate ? "已自评" : "未自评" }}
                  </div>
                </div>
                <div
                  style="color: rgb(112, 181, 3)"
                  @click="lookStudent(item.studentsNo)"
                >
                  去批阅 >
                </div>
              </div>
              <div class="studentInfo">
                <div>订正总数：{{ item.statistics.prierTotal }}</div>
                <div>错题总数：{{ item.statistics.wrongTotal }}</div>
                <div>做题正确率：{{ item.statistics.truePercent }}%</div>
                <div>疑问题数：{{ item.statistics.incomprehensionTotal }}</div>
              </div>
            </li>
            <li v-if="!studentList.length">
              <van-empty
                :image="require('@/assets/images/kong.png')"
                description="暂无待批阅的学生"
              />
            </li>
          </ul>
        </van-tab>
        <van-tab title="已批阅">
          <ul class="listBox">
            <li v-for="item in isReviewStudentList" :key="item.id">
              <div class="nameAndReview">
                <div class="name">
                  <van-image
                    width="40px"
                    height="40px"
                    :src="
                      item.sex == '0'
                        ? require('@/assets/images/boy.png')
                        : require('@/assets/images/girl.png')
                    "
                  />
                  <div class="studentName">{{ item.studentName }}</div>
                  <div style="color: #d1d1d1; font-size: 10px">
                    {{ item.is_evaluate ? "已自评" : "未自评" }}
                  </div>
                </div>
                <div
                  style="color: rgb(112, 181, 3)"
                  @click="lookStudent(item.studentsNo)"
                >
                  去查看 >
                </div>
              </div>
              <div class="studentInfo">
                <div>订正总数：{{ item.statistics.prierTotal }}</div>
                <div>错题总数：{{ item.statistics.wrongTotal }}</div>
                <div>做题正确率：{{ item.statistics.truePercent }}%</div>
                <div>疑问题数：{{ item.statistics.incomprehensionTotal }}</div>
              </div>
            </li>
            <li v-if="!isReviewStudentList.length">
              <van-empty
                :image="require('@/assets/images/kong.png')"
                description="暂无已批阅的学生"
              />
            </li>
          </ul>
        </van-tab>
      </van-tabs>
    </div>

    <van-popup
      v-model:show="showCase"
      position="left"
      :style="{ height: '100%', width: '50%' }"
    >
      <van-list
        v-model:loading="caseLoading"
        :finished="caseLoadingFinished"
        finished-text="没有更多了"
        @load="getCaseList"
      >
        <ul class="caseListBox">
          <h2>选择要切换的学案</h2>
          <li
            v-for="item in caseList"
            :key="item.id"
            @click="changeCase(item.id)"
          >
            {{ item.title }}
          </li>
        </ul>
      </van-list>
    </van-popup>

    <van-popup
      v-model:show="showClass"
      position="bottom"
      :style="{ height: '50%' }"
    >
      <van-picker
        title="切换班级"
        :columns="classList"
        @confirm="onConfirmClass"
        @cancel="showClass = false"
      />
    </van-popup>
  </div>
</template>

<script setup>
import { ref, onBeforeMount } from "vue";
import { useRouter, useRoute } from "vue-router";
import { apiGetCaseInfo, apiGetStudentList } from "@/service/teacher1.3.js";
import { apiGetCasesList } from "@/service/teacher.js";

const router = useRouter();
const route = useRoute();

let currentRate1 = ref(0);
let currentRate2 = ref(0);
let isReviewActive = ref(0);
let showCase = ref(false);
let showClass = ref(false);

onBeforeMount(async () => {
  await getCaseInfo();
  await getStudentList();
  await getCaseList();
});

let caseInfo = ref({});
let className = ref("");
let classList = ref([]);
let classId = ref("");
const getCaseInfo = async (gradeId, classId) => {
  let res = await apiGetCaseInfo({
    case_id: route.query.case_id,
    gradeId,
    classId,
  });
  if (res) {
    caseInfo.value = res.data;
    className.value = className.value
      ? className.value
      : res.data.gradeClassArr[0].name;
    classList.value = res.data.gradeClassArr.map((item) => {
      return {
        text: item.name,
        value: item.class_id,
      };
    });
  }
};

const onConfirmClass = async (e) => {
  showClass.value = false;
  className.value = e.text;
  classId.value = e.value;
  studentList.value = [];
  isReviewStudentList.value = [];
  await getCaseInfo(caseInfo.value.gradeId, e.value);
  await getStudentList();
};
const changeCase = async (id) => {
  await router.push({ path: "/teacher/fastReview", query: { case_id: id } });
  showCase.value = false;
  className.value = "";
  classId.value = "";
  await getCaseInfo();
  studentList.value = [];
  isReviewStudentList.value = [];
  await getStudentList();
};

// 获取学生列表
let studentList = ref([]);
let isReviewStudentList = ref([]);
const getStudentList = async () => {
  let res = await apiGetStudentList({
    caseID: route.query.case_id,
    gradeId: caseInfo.value.gradeClassArr[0].grade_id,
    classId: classId.value
      ? classId.value
      : caseInfo.value.gradeClassArr[0].class_id,
    isFinished: 0,
  });
  if (res) {
    // classId.value = "";
    res.data.forEach((item) => {
      if (item.isFinished && !item.is_review) {
        studentList.value.push(item);
      }
      if (item.isFinished && item.is_review) {
        isReviewStudentList.value.push(item);
      }
    });
  }
};

// 列表loading效果
let caseLoading = ref(true);
// 结束loading效果
let caseLoadingFinished = ref(false);

let page = 0;
let limit = 20;
let caseList = ref([]);
const getCaseList = async () => {
  page++;
  let res = await apiGetCasesList({
    page: page,
    limit: limit,
    searchTitle: "",
    isToday: "",
    appointedDate: "",
    subjectId: "",
    bookId: "",
    chapterTreeId: "",
    gradeId: "",
    classId: "",
  });
  if (res) {
    caseLoading.value = false;

    caseList.value = caseList.value.concat(res.data);

    if (res.data.length < limit) {
      caseLoadingFinished.value = true;
    }
  } else {
    caseLoading.value = false;
    caseLoadingFinished.value = true;
  }
};

const lookStudent = (No) => {
  router.push({
    path: "/teacher/studentwork",
    query: {
      No,
      case_id: route.query.case_id,
      gradeId: caseInfo.value.gradeClassArr[0].grade_id,
      classId: classId.value
        ? classId.value
        : caseInfo.value.gradeClassArr[0].class_id,
    },
  });
};

const onClickLeft = () => router.push("/teacher/case/list");
</script>

<style lang="less" scoped>
.headerInfo {
  width: 80%;
  padding: 10px;
  box-sizing: border-box;
  height: 200px;
  margin: 10px auto;
  background-color: #fff;
  font-size: 12px;
  .titleAndClass {
    display: flex;
    justify-content: space-between;
    .switchSubject,
    .title,
    .class {
      display: flex;
      align-items: center;
    }
  }

  .subject {
    background-color: rgb(226, 245, 196);
    padding: 3px 5px;
    border-radius: 5px;
    color: rgb(112, 181, 3);
    margin-right: 5px;
  }

  .switchSubject {
    margin-left: 10px;
    background-color: #eee;
    padding: 3px 5px;
    border-radius: 5px;
    font-size: 10px;
  }

  .bookAndTime {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .bookAndTree {
    display: flex;
    align-items: center;
  }

  .cir {
    width: 50%;
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
  }
}

.studentList {
  width: 80%;
  margin: auto;
  .listBox {
    background-color: #fff;
    padding-top: 20px;
    border-top: 1px solid #eee;
    font-size: 11px;
    li {
      padding: 10px;
      border-bottom: 1px solid #eee;
      .studentName {
        margin: 10px;
      }

      &:nth-last-child(1) {
        border-bottom: none;
      }
    }
    .nameAndReview {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .name {
        display: flex;
        align-items: center;
      }
    }
    .studentInfo {
      display: flex;
      margin-top: 10px;
      justify-content: space-between;
    }
  }
}

.caseListBox {
  text-align: center;
  position: relative;
  padding: 0 20px;
  li {
    font-size: 12px;
    border-bottom: 1px solid #eee;
    height: 30px;
    line-height: 30px;
    &:nth-last-child(1) {
      border-bottom: none;
    }
  }
}

@media screen and (orientation: portrait) and (max-device-width: 420px) {
  .headerInfo {
    width: 100%;
    height: 220px;
    .bookAndTime {
      display: block;
      div {
        margin-bottom: 5px;
      }
    }
    .cir {
      width: 100%;
      margin: 20px auto;
      display: flex;
      justify-content: space-between;
    }
  }
  .studentList {
    width: 100%;
    margin: auto;
  }
}

:deep(.van-circle__text) {
  font-size: 9px;
}
</style>
